var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"fill-height",attrs:{"fluid":""}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('v-card',{attrs:{"loading":_vm.loading}},[_c('v-form',{ref:"form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-toolbar',{attrs:{"color":"primary","dark":"","flat":""}},[_c('v-toolbar-title',[_vm._v("Регистрация")])],1),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"E-mail","prepend-icon":"$vuetify.icons.gmail","required":"","rules":_vm.emailRules},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('v-text-field',{attrs:{"label":"Никнэйм","prepend-icon":"$vuetify.icons.account","rules":_vm.loginRules},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('v-text-field',{attrs:{"prepend-icon":"$vuetify.icons.lock","append-icon":_vm.showPassword
                      ? '$vuetify.icons.eye'
                      : '$vuetify.icons.eye_off',"type":_vm.showPassword ? 'text' : 'password',"label":"Пароль","hint":"Минимум 8 символов","rules":_vm.passwordRules,"required":""},on:{"click:append":function($event){_vm.showPassword = !_vm.showPassword}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('v-text-field',{attrs:{"prepend-icon":"$vuetify.icons.lock_plus","append-icon":_vm.showPassword2
                      ? '$vuetify.icons.eye'
                      : '$vuetify.icons.eye-off',"type":_vm.showPassword2 ? 'text' : 'password',"label":"Повторите пароль","hint":"Минимум 8 символов","rules":_vm.confirmPasswordRules,"required":""},on:{"click:append":function($event){_vm.showPassword2 = !_vm.showPassword2}},model:{value:(_vm.password_confirmation),callback:function ($$v) {_vm.password_confirmation=$$v},expression:"password_confirmation"}}),_c('v-checkbox',{staticClass:"register-accept",scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',[_vm._v(" Я согласен с "),_c('router-link',{attrs:{"to":{
                          name: 'Page',
                          params: { slug: 'polzovatelskoe-soglashenie' },
                        }}},[_vm._v("Пользовательским соглашением")]),_vm._v(", "),_c('router-link',{attrs:{"to":{
                          name: 'Page',
                          params: { slug: 'privacy-policy' },
                        }}},[_vm._v("Политикой конфиденциальности")]),_vm._v("и "),_c('router-link',{attrs:{"to":{
                          name: 'Page',
                          params: { slug: 'pravila-sajta' },
                        }}},[_vm._v("Правилами сайта")])],1)]},proxy:true}]),model:{value:(_vm.accept),callback:function ($$v) {_vm.accept=$$v},expression:"accept"}}),_c('vue-recaptcha',{ref:"recaptcha",staticClass:"mb-4",attrs:{"sitekey":_vm.$root.gcKey,"loadRecaptchaScript":true},on:{"verify":_vm.onVerifyGC,"expired":_vm.onExpiredGC}}),_c('v-btn',{staticClass:"mt-2 register-button",attrs:{"disabled":!_vm.accept,"dark":"","color":"primary"},on:{"click":_vm.submit}},[_vm._v("Регистрация")])],1),_c('v-col',{attrs:{"cols":"12"}},[_c('router-link',{attrs:{"to":{ name: 'ForgotPassword' }}},[_vm._v("Забыли пароль?")]),_c('router-link',{staticClass:"ml-2",attrs:{"to":{ name: 'Login' }}},[_vm._v("Авторизация")])],1)],1)],1),_c('v-card-text',[_c('social')],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }