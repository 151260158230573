<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" md="8">
        <v-card :loading="loading" class>
          <v-form v-model="valid" ref="form">
            <v-toolbar color="primary" dark flat>
              <v-toolbar-title>Регистрация</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <!-- <social></social> -->
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="email"
                    label="E-mail"
                    prepend-icon="$vuetify.icons.gmail"
                    required
                    :rules="emailRules"
                  ></v-text-field>
                  <v-text-field
                    v-model="name"
                    label="Никнэйм"
                    prepend-icon="$vuetify.icons.account"
                    :rules="loginRules"
                  ></v-text-field>

                  <!-- <v-menu
                    ref="birthday_menu"
                    v-model="birthday_menu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="birthday_date"
                        label="Дата рождения"
                        prepend-icon="$vuetify.icons.calendar"
                        readonly
                        v-on="on"
                        v-bind="attrs"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      locale="ru-ru"
                      ref="picker"
                      :first-day-of-week="1"
                      v-model="birthday_date"
                      :max="new Date().toISOString().substr(0, 10)"
                      min="1950-01-01"
                      :active-picker.sync="activePicker"
                      scrollable
                    >
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="birthday_menu = false"
                        >Закрыть</v-btn
                      >
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.birthday_menu.save(birthday_date)"
                        >Принять</v-btn
                      >
                    </v-date-picker>
                  </v-menu> -->


                  <v-text-field
                    v-model="password"
                    prepend-icon="$vuetify.icons.lock"
                    :append-icon="
                      showPassword
                        ? '$vuetify.icons.eye'
                        : '$vuetify.icons.eye_off'
                    "
                    :type="showPassword ? 'text' : 'password'"
                    label="Пароль"
                    hint="Минимум 8 символов"
                    @click:append="showPassword = !showPassword"
                    :rules="passwordRules"
                    required
                  ></v-text-field>

                  <v-text-field
                    v-model="password_confirmation"
                    prepend-icon="$vuetify.icons.lock_plus"
                    :append-icon="
                      showPassword2
                        ? '$vuetify.icons.eye'
                        : '$vuetify.icons.eye-off'
                    "
                    :type="showPassword2 ? 'text' : 'password'"
                    label="Повторите пароль"
                    hint="Минимум 8 символов"
                    @click:append="showPassword2 = !showPassword2"
                    :rules="confirmPasswordRules"
                    required
                  ></v-text-field>

                  <v-checkbox class="register-accept" v-model="accept">
                    <template v-slot:label>
                      <div>
                        Я согласен с
                        <router-link
                          :to="{
                            name: 'Page',
                            params: { slug: 'polzovatelskoe-soglashenie' },
                          }"
                          >Пользовательским соглашением</router-link
                        >,
                        <router-link
                          :to="{
                            name: 'Page',
                            params: { slug: 'privacy-policy' },
                          }"
                          >Политикой конфиденциальности</router-link
                        >и
                        <router-link
                          :to="{
                            name: 'Page',
                            params: { slug: 'pravila-sajta' },
                          }"
                          >Правилами сайта</router-link
                        >
                      </div>
                    </template>
                  </v-checkbox>
                  <vue-recaptcha
                    class="mb-4"
                    ref="recaptcha"
                    @verify="onVerifyGC"
                    @expired="onExpiredGC"
                    :sitekey="$root.gcKey"
                    :loadRecaptchaScript="true"
                  ></vue-recaptcha>
                  <v-btn
                    :disabled="!accept"
                    class="mt-2 register-button"
                    dark
                    color="primary"
                    @click="submit"
                    >Регистрация</v-btn
                  >
                </v-col>
                <v-col cols="12">
                  <!-- <a @click="showReset =! showReset">Забыли пароль?</a> -->
                  <router-link :to="{ name: 'ForgotPassword' }"
                    >Забыли пароль?</router-link
                  ><router-link class="ml-2" :to="{ name: 'Login' }"
                    >Авторизация</router-link
                  >
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-text>
              <social></social>
            </v-card-text>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
// import { bus } from "../mixins/bus.js";
import VueRecaptcha from "vue-recaptcha";
import Social from "./Social.vue";
export default {
  components: {
    Social,
    VueRecaptcha,
  },
  data: () => ({
    valid: true,
    loading: false,
    showPassword: false,
    showPassword2: false,
    name: "",
    email: "",
    password: "",
    password_confirmation: "",
    remember: false,
    requiredRules: [(v) => !!v || "Поле обязательно"],
    loginRules: [
      (v) => !!v || "Поле обязательно",
      (v) => (v && v.length >= 3) || "Минимум 3 символа",
      //   v => /^[\w-]+$/gim.test(v) || "Только латинские символы и цифры",
      (v) => /^[\w-]*$/.test(v) || "Введите ваше имя английскими буквами.",
    ],
    passwordRules: [
      (v) => !!v || "Поле обязательно",
      (v) => (v && v.length >= 8) || "Минимум 8 символов",
    ],

    emailRules: [
      (v) => !!v || "Поле обязательно",
      (v) => /.+@.+\..+/.test(v) || "Не верно введен E-mail",
    ],
    accept: true,
    birthday_menu: false,
    birthday_date: null,
    activePicker: null,
    captcha: "",
  }),

  methods: {
    validate() {
      return this.$refs.form.validate();
    },
    onVerifyGC: function (response) {
      // console.log('Verify: ' + response)
      this.captcha = response;
    },
    onExpiredGC: function () {
      // console.log('Expired')
      this.captcha = "";
    },
    resetGC() {
      this.$refs.recaptcha.reset();
      this.captcha = "";
    },
    submit() {
      event.preventDefault();
      if (!this.validate() || !this.captcha) return;
      this.loading = true;
      this.$store
        .dispatch("register", {
          name: this.name,
          email: this.email,
          password: this.password,
          birthday_date: this.birthday_date,
          captcha: this.captcha,
        })
        .then(() => {
          this.loading = false;
          // console.log(responce);
          this.resetGC();
          if (this.$route.query.return_url) {
            if (this.$route.query.return_url) {
              this.$router.push(this.$route.query.return_url);
            } else {
              this.$router.push({ name: "Home" });
            }
            // this.$router.push({
            //   name: "Login",
            //   query: { return_url: this.$route.query.return_url },
            // });
          } else {
            this.$router.push({ name: "Login" });
          }
          this.$metrika.reachGoal("register");
        })
        .catch(() => {
          this.resetGC();
          this.loading = false;
        });
    },
    clear() {
      this.email = "";
      this.password = "";
      this.resetGC();
    },
  },
  computed: {
    confirmPasswordRules() {
      const rules = [];

      const rule = (v) =>
        (!!v && v) === this.password || "Пароли должны совпадать";

      rules.push(rule);

      return rules;
    },
  },
  watch: {
    birthday_menu(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
      // val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
    },
  },
  mounted() {
    // window.axios.get("/api/user").then(response => {
    //   console.log(response.data);
    // });
  },
};
</script>
